import type {
  WebShellNewRelicPageActionName,
  WebShellNewRelicPageActionAttributes
} from "@nike/web-shell-types/dist/analytics/NewRelicPageAction";

interface ProcessedNewRelicPageActionAttributes {
  [index: string]: string | number;
}

const removeUndefinedProperties = (attributes: {}): ProcessedNewRelicPageActionAttributes => {
  const stringifiedAttributes = JSON.stringify(attributes);

  return JSON.parse(stringifiedAttributes);
};

export const addPageAction = (
  pageActionName: WebShellNewRelicPageActionName,
  attributes: WebShellNewRelicPageActionAttributes | {} = {}
): void => {
  if (typeof window !== `undefined`) {
    const attributesWithVersion = {
      ...attributes,
      webShellClientVersion: window.webShellClient.__VERSION__ ?? ``
    };

    const processedAttributes = removeUndefinedProperties(attributesWithVersion);

    window.newrelic?.addPageAction(pageActionName, processedAttributes);
  }
};
