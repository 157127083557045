// Countries that should be bucketed to Accounts
export const accountsCountries = [`cn`, `kr`];
// Countries that should be bucketed to Unite
export const uniteCountries = [
  `ae`,
  `au`,
  `bg`,
  `br`,
  `ca`,
  `ch`,
  `hr`,
  `id`,
  `il`,
  `in`,
  `mx`,
  `my`,
  `no`,
  `nz`,
  `ph`,
  `ro`,
  `ru`,
  `sa`,
  `sg`,
  `sk`,
  `th`,
  `tr`,
  `tw`,
  `vn`,
  `za`
];
