import type {
  OptimizationInitProperties,
  WebShellClient,
  WebShellClientOptions,
  WebShellOptimization
} from "@nike/web-shell-types";
import { DotcomWebShellLocale } from "./locale";
import { DotcomWebShellTranslations } from "./translations";
import { ModalManager, defaultModalRoot } from "./modals";
import { ReactInstanceFactory } from "./helpers/reactInstanceFactory";
import { DotcomWebShellUserLocation } from "./userLocation";
import {
  defaultAnalyticsOptions,
  dotcomNoopAnalyticsFactory,
  dotcomWebShellAnalyticsFactory
} from "./analytics";
import { makeIdentity } from "./identity/identity";
import { getWebShellClientInfo } from "./internal/webShellClientInfo";
import { DotcomWebShellAdobeTargetOptimization } from "./optimization/adobe/adobeTargetOptimization";
import { DotcomWebShellNoopOptimization } from "./optimization/noop/noopOptimization";

export const defaultWebShellClientOptions: WebShellClientOptions = {
  appName: `Web Shell Application`,
  initialLocale: DotcomWebShellLocale.defaultLocale,
  modal: { rootSelector: defaultModalRoot },
  isProduction: false,
  analytics: defaultAnalyticsOptions,
  optimization: {
    locations: []
  }
};

export class WebShellClientFactory {
  static getInstance = (options: WebShellClientOptions): WebShellClient => {
    const locale = new DotcomWebShellLocale(options.initialLocale);
    const userLocation = new DotcomWebShellUserLocation();
    const translations = new DotcomWebShellTranslations(locale);
    const modalManager = new ModalManager(options.modal?.rootSelector);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { getLocations } = WebShellClientFactory.getDotcomWebShellOptimization(
      options.optimization
    );

    const identity = makeIdentity({ locale });
    // Temporary pass through to window.analyticsClient
    const analyticsOptions = options.analytics ?? defaultWebShellClientOptions.analytics;

    const webShellClientVersion = process.env.WEB_SHELL_CLIENT_VERSION;

    let analytics = dotcomNoopAnalyticsFactory();

    if (typeof window !== `undefined`) {
      window.newrelic?.addPageAction(`WEB_SHELL_CLIENT_INITIALIZED`, {
        webShellClientVersion: webShellClientVersion ?? ``
      });

      if (typeof window.analyticsClient !== `undefined`) {
        // TODO: Deep-merge this to allow nested objects in https://jira.nike.com/browse/WFLX-932
        analytics = dotcomWebShellAnalyticsFactory(analyticsOptions!);
      }
    }

    return {
      __VERSION__: webShellClientVersion,
      appName: options.appName ?? ``,
      analytics,
      identity,
      locale,
      modalManager,
      translations,
      optimization: {
        __WS_UNSTABLE__: {
          getLocations
        }
      },
      userLocation,
      ReactInstanceFactory,
      isProduction: options.isProduction ?? defaultWebShellClientOptions.isProduction,
      __WS_INTERNAL__: { getInfo: getWebShellClientInfo }
    };
  };

  private static getDotcomWebShellOptimization = (
    options?: OptimizationInitProperties
  ): WebShellOptimization => {
    if (options?.adobe?.enabled) {
      return new DotcomWebShellAdobeTargetOptimization(options.locations);
    }

    return new DotcomWebShellNoopOptimization(options?.locations);
  };
}
