import type { VisitData } from "@nike/web-shell-types";

export const WEB_SHELL_CLIENT_VISIT_DATA_STORAGE_KEY = `webShellClientVisitData`;

const defaultVisitData: VisitData = {
  visitId: 0,
  visitorId: ``
};

export const readVisitData = (): VisitData => {
  const visitDataString = window.localStorage.getItem(WEB_SHELL_CLIENT_VISIT_DATA_STORAGE_KEY);

  const visitData: VisitData = visitDataString ? JSON.parse(visitDataString) : defaultVisitData;

  return visitData;
};

export const writeVisitData = (visitData: VisitData): void => {
  window.localStorage.setItem(WEB_SHELL_CLIENT_VISIT_DATA_STORAGE_KEY, JSON.stringify(visitData));
};

export const removeVisitData = (): void => {
  window.localStorage.removeItem(WEB_SHELL_CLIENT_VISIT_DATA_STORAGE_KEY);
};
