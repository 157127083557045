import type { LocaleData, WebShellLocale } from "@nike/web-shell-types";
import { DEFAULT_LOCALE_DATA } from "@nike/web-shell-types";

export class DotcomWebShellLocale implements WebShellLocale {
  /**
   * @deprecated Use DEFAULT_LOCALE_DATA from "@nike/web-shell-types" instead
   */
  static defaultLocale: LocaleData = DEFAULT_LOCALE_DATA;

  private readonly current: LocaleData;

  /**
   * Tracks whether or not analytics initialization has been tracked in New Relic
   */
  private hasTrackedInit = false;

  constructor(initialLocale: LocaleData = DEFAULT_LOCALE_DATA) {
    this.current = initialLocale;
  }

  get = (): LocaleData => {
    if (typeof window === `undefined`) {
      // This was called somewhere besides the browser.
      // This method isn't supported on the server or in a worker, but we don't want to break
      // users' applications if they rely on code that calls this method.

      // eslint-disable-next-line no-console
      console.warn(
        [
          `Warning! shell.locale.get() is only meant to be used on the client. We don't know what the current locale is on the server without extra info.`,
          `Use parseRequestLocale from @nike/web-shell-server-utils to get the locale server-side.`,
          `If you need the locale server-side in React, pass it to the LocaleProvider via the locale prop.`
        ].join(`\n\n`)
      );
    } else if (!this.hasTrackedInit) {
      window.newrelic?.addPageAction(`WEB_SHELL_CLIENT_LOCALE_INITIALIZED`, {
        webShellClientVersion: process.env.WEB_SHELL_CLIENT_VERSION ?? ``
      });

      this.hasTrackedInit = true;
    }

    return this.current;
  };
}
