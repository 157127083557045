import { WebShellOptimization } from "@nike/web-shell-types";
import type { GetLocationsResult, Location } from "@nike/web-shell-types";

export class DotcomWebShellNoopOptimization extends WebShellOptimization {
  constructor(locations?: Location[]) {
    super(locations);
    // eslint-disable-next-line no-console
    console.warn(
      `Optimization did not initialize. If you expected optimization to be enabled please check your configuration and restart the application.`
    );
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public getLocations = async (): Promise<GetLocationsResult | undefined> => {
    // eslint-disable-next-line no-console
    console.warn(
      `Optimization is either disabled or failed to initialize correctly so getLocations returns undefined`
    );

    // eslint-disable-next-line no-useless-return
    return;
  };
}
