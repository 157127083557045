import type { ModalManager as ModalManagerInterface } from "@nike/web-shell-types";

export const defaultModalRoot = `#modal-root`;

export class ModalManager implements ModalManagerInterface {
  public activeModalID: string | null;
  public refEl: HTMLElement | null;
  public rootSelector: string;

  constructor(rootSelector: string = defaultModalRoot) {
    this.rootSelector = rootSelector;
    this.activeModalID = null;
  }

  setActiveModalID: ModalManagerInterface["setActiveModalID"] = (id) => {
    this.activeModalID = id;
  };

  setReferringElement: ModalManagerInterface["setReferringElement"] = (refEl) => {
    this.refEl = refEl;
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeVanilla: ModalManagerInterface["closeVanilla"] = (): void => {};
}
