import type {
  AnalyticsCallback,
  TrackOptions,
  WebShellAnalytics,
  WebShellAnalyticsContext,
  WebShellAnalyticsEvent,
  WebShellAnalyticsOptions
} from "@nike/web-shell-types";

export const TEST_SEGMENT_WRITE_KEY = `POwa4r8vBBSw7xdQZ0dqGlNuyaT7Y7pZ`;

export const defaultAnalyticsOptions: WebShellAnalyticsOptions = {
  writeKey: TEST_SEGMENT_WRITE_KEY
};

export function dotcomNoopAnalyticsFactory(): WebShellAnalytics {
  const context = {};

  function getContext(): WebShellAnalyticsContext {
    const errorMessage = `Web Shell Client Analytics: analytics.getContext() called but no analytics client is configured.  Please supply window.analyticsClient to use Web Shell's analytics feature`;

    /* eslint-disable no-console */
    console.warn(errorMessage);
    /* eslint-enable */

    if (window.newrelic?.noticeError) {
      window.newrelic.noticeError(errorMessage);
    }

    return context;
  }

  function load(writeKey: string): void {
    const errorMessage = `Web Shell Client Analytics: analytics.load() called but no analytics client is configured.  Please supply window.analyticsClient to use Web Shell's analytics feature`;

    /* eslint-disable no-console */
    console.warn(errorMessage);
    console.log(`.load() called with write key: ${writeKey}`);
    /* eslint-enable */

    if (window.newrelic?.noticeError) {
      window.newrelic.noticeError(errorMessage);
    }
  }

  function debug(enable?: false): void {
    const errorMessage = `Web Shell Client Analytics: analytics.debug() called but no analytics client is configured.  Please supply window.analyticsClient to use Web Shell's analytics feature`;

    /* eslint-disable no-console */
    console.warn();
    console.log(`.debug() called with: `, enable);
    /* eslint-enable */

    if (window.newrelic?.noticeError) {
      window.newrelic.noticeError(errorMessage);
    }
  }

  function track(
    eventName: unknown,
    properties: unknown,
    options?: TrackOptions,
    callback?: AnalyticsCallback
  ): void;
  function track<TProperties, TEvent extends WebShellAnalyticsEvent<TProperties>>(
    event: TEvent /*extends WebShellAnalyticsEvent<infer U> ? U : never*/
  ): void;
  // eslint-disable-next-line promise/prefer-await-to-callbacks
  function track(eventName: any, properties?: any, options?: any, callback?: any): void {
    const errorMessage = `Web Shell Client Analytics: analytics.track() called but no analytics client is configured.  Please supply window.analyticsClient to use Web Shell's analytics feature`;

    /* eslint-disable no-console */
    console.warn(errorMessage);
    console.log(`.track() called with: `, eventName, properties, options, callback);
    /* eslint-enable */

    if (window.newrelic?.noticeError) {
      window.newrelic.noticeError(errorMessage);
    }
  }

  function page<TPageEventProperties>(
    pageCategory: unknown,
    pageName: unknown,
    properties: TPageEventProperties,
    options?: TrackOptions,
    callback?: AnalyticsCallback
  ): void;

  function page<TEvent>(event: TEvent): void;

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  function page(
    pageCategory: any,
    pageName?: any,
    properties?: any,
    options?: any,
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    callback?: any
  ): void {
    const errorMessage = `Web Shell Client Analytics: analytics.page() called but no analytics client is configured.  Please supply window.analyticsClient to use Web Shell's analytics feature`;

    /* eslint-disable no-console */
    console.warn(errorMessage);
    console.log(`.page() called with: `, pageCategory, pageName, properties, options, callback);
    /* eslint-enable */

    if (window.newrelic?.noticeError) {
      window.newrelic.noticeError(errorMessage);
    }
  }
  return {
    getContext,
    load,
    debug,
    track,
    page
  };
}

export function dotcomWebShellAnalyticsFactory(
  analyticsOptions: WebShellAnalyticsOptions
): WebShellAnalytics {
  /**
   * Tracks whether or not analytics initialization has been tracked in New Relic
   */
  let hasTrackedInit = false;

  const finalOptions = { ...defaultAnalyticsOptions, ...analyticsOptions };

  /*
   *  If writeKey isn't configured, set writeKey to the value of segmentWriteKey if it's present.
   *  TODO: Remove this functionality as a breaking change in https://jira.nike.com/browse/TG-4862
   */

  if (analyticsOptions.segmentWriteKey) {
    if (!analyticsOptions.writeKey) {
      finalOptions.writeKey = analyticsOptions.segmentWriteKey;
    }

    delete finalOptions.segmentWriteKey;
  }

  const context = {};

  /**
   * Calls window.analyticsClient.load() and sets the Segment write key to use for all
   * .page() and .track() calls
   * @param writeKey - The write key to use on all .page() and .track()
   * calls. NOTE: If supplied, this will override the value passed in the analyticsOptions argument
   * of the factory
   */
  function load(writeKey?: string): void {
    if (writeKey) {
      finalOptions.writeKey = writeKey;
    }
    window.analyticsClient.load();

    if (!hasTrackedInit) {
      window.newrelic?.addPageAction(`WEB_SHELL_CLIENT_ANALYTICS_INITIALIZED`, {
        webShellClientVersion: process.env.WEB_SHELL_CLIENT_VERSION ?? ``
      });
      hasTrackedInit = true;
    }
  }

  // TODO: Proper typing of entire context object is needed for IDE Validation
  function getContext(): WebShellAnalyticsContext {
    return context;
  }

  // TODO: setViewProperties, setAppProperties, setCommonProperties to update parts of the context

  function debug(enable?: false): void | Error {
    window.analyticsClient.debug(enable);
  }

  /**
   * Aligns with analytics.track() function.  Consumes new data, and uses propertyReplacer
   * to replace variables with values from context before submission.
   * @param eventName Name of analytics event
   * @param properties Additional properties required for the event
   * @param options Any additional track options
   */
  function track(eventName: unknown, properties: unknown, options?: TrackOptions): void;
  function track<TEvent>(event: TEvent extends WebShellAnalyticsEvent<infer U> ? U : never): void;
  function track(
    eventNameOrEvent: any,
    maybeProperties?: any,
    maybeOptions?: any,
    maybeCallback?: any
  ): void {
    let options = finalOptions;

    if (maybeOptions && typeof maybeOptions === `object`) {
      options = {
        ...options,
        ...maybeOptions
      };
    }

    // For now, just pass straight through to window.analyticsClient.
    // TODO: Update these properties with the values in the context
    window.analyticsClient.track(eventNameOrEvent, maybeProperties, options, maybeCallback);
  }

  /**
   * Aligns with analytics.page() function. Adds writeKey if not present.
   * @param pageCategory
   * @param pageName
   * @param properties
   * @param options
   */
  function page<TPageEventProperties>(
    pageCategory: unknown,
    pageName: unknown,
    properties: TPageEventProperties,
    options?: TrackOptions
  ): void;
  function page<TEvent>(event: TEvent): void;
  function page(
    pageCategoryOrEvent: unknown,
    maybePageName?: string,
    maybeProperties?: any,
    maybeOptions?: any
  ): void {
    let options = finalOptions;

    if (maybeOptions && typeof maybeOptions === `object`) {
      options = {
        ...options,
        ...maybeOptions
      };
    }

    // For now, just pass straight through to window.analyticsClient.
    // TODO: Update these properties with the values in the context
    window.analyticsClient.page(pageCategoryOrEvent, maybePageName, maybeProperties, options);
  }

  return {
    getContext,
    load,
    debug,
    track,
    page
  };
}
