import Cookies from "js-cookie";
import type {
  IdentityProviderReason,
  InitializationSettings,
  UserProfile,
  VisitData,
  WebShellIdentity,
  UniteUser,
  UniteUserManager,
  WebShellLocale
} from "@nike/web-shell-types";
import type {
  SignoutResponse,
  User,
  UserManager as UserManagerType,
  WebStorageStateStore as WebStorageStateStoreType
} from "oidc-client";
import { removeVisitData } from "./storage";
import { getVisitData as sharedGetVisitData } from "./shared";
import { accountsCountries, uniteCountries } from "./identityConfig";
import { webShellClientInfoDispatch } from "../internal/webShellClientInfo";
import { addPageAction } from "../newRelicUtils";

const isSwooshUser = (
  profile: Partial<{ userType: string; swoosh?: boolean }> | undefined
): boolean => {
  if (profile?.userType === `EMPLOYEE` || profile?.swoosh) {
    return true;
  }
  return false;
};

// input data is either user.profile from oid userManager's .getUser or profile object from success callback of session.getUserProfile
const shapeUserProfile = (data: any): UserProfile => {
  const profile: UserProfile = {
    avatarUrl:
      data?.picture || data?.avatar_url || data?.entity?.avatar?.fullUrl || data?.avatarUrl,
    firstName: data?.given_name || data?.entity?.firstName || data?.firstName,
    altFirstName:
      data?.name?.kana?.given ||
      data?.name?.alternate?.given ||
      data?.entity?.jpFirstNameKana ||
      data?.altFirstName,
    altLastName:
      data?.name?.kana?.family ||
      data?.name?.alternate?.family ||
      data?.entity?.jpLastNameKana ||
      data?.altLastName,
    lastName: data?.family_name || data?.entity?.lastName || data?.lastName,
    email: data?.email || data?.entity?.account?.email,
    mobileNumber: data?.phone_number || data?.entity?.mobileNumber || data?.mobileNumber,
    upmId: data?.sub || data?.entity?.account?.id || data?.upmId,
    registeredCountry: data?.country,
    signInFlow: data?.flow,
    userType: `GUEST`
  };
  if (isSwooshUser(data)) {
    profile.userType = `SWOOSH`;
  } else if (profile.upmId) {
    profile.userType = `MEMBER`;
  }
  return profile;
};

///////////////////////////////
// OidcClient config
///////////////////////////////
// Oidc.Log.logger = console;
// Oidc.Log.level = Oidc.Log.INFO;

const callBackRouteRegex = /^(?:\/auth\/(?:login|logout|silent-renew))|^(?:\/(?:[a-z]{2}\/(?:[a-z]{2}(?:-[A-z]{4})?\/)?)?(?:login|register))/;
const isNotACallbackRoute = (): boolean =>
  callBackRouteRegex.exec(window.location.pathname) === null;
function createOIDCSettings(userStore: WebStorageStateStoreType): Object {
  return {
    authority: process.env.NEXT_PUBLIC_AUTHORITY,
    client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
    redirect_uri: process.env.NEXT_PUBLIC_REDIRECT_URI ?? `${window.location.origin}/auth/login`,
    post_logout_redirect_uri:
      process.env.NEXT_PUBLIC_POST_LOGOUT_REDIRECT_URI ?? `${window.location.origin}/auth/logout`,
    silent_redirect_uri:
      process.env.NEXT_PUBLIC_SILENT_REDIRECT_URI ?? `${window.location.origin}/auth/silent-renew`,
    response_type: `code`,
    scope: `openid nike.digital profile email phone flow country`,
    loadUserInfo: false,
    automaticSilentRenew: true,
    userStore,
    monitorSession: isNotACallbackRoute()
  };
}

// Helper function to determine unite vs accounts.  Exported for testing, not for external use.
export function getIsIdentityProviderUnite(
  locale: WebShellLocale
): { isUnite: boolean; reason: IdentityProviderReason } {
  /**
   * unite_session and unite_mobile_session are for internal use ONLY and external consumers should not make use of them.
   */
  const uniteSessionCookie = Cookies.get(`unite_session`);
  const uniteMobileSessionCookie = Cookies.get(`unite_mobile_session`);
  const identityProviderCookie = Cookies.get(`ws_identity_provider`);
  const localeCountry = locale.get().country;

  if (identityProviderCookie === `accounts`) {
    return { isUnite: false, reason: `developerCookie` };
  } else if (identityProviderCookie === `unite`) {
    return { isUnite: true, reason: `developerCookie` };
  } else if (uniteSessionCookie === `1` || uniteMobileSessionCookie === `1`) {
    return { isUnite: true, reason: `uniteSessionCookie` };
  } else if (accountsCountries.includes(localeCountry)) {
    return { isUnite: false, reason: `accountsCountry` };
  } else if (uniteCountries.includes(localeCountry)) {
    return { isUnite: true, reason: `uniteCountry` };
  }
  return { isUnite: false, reason: `fallthrough` };
}

export function makeIdentity({ locale }: InitializationSettings): WebShellIdentity {
  let identityObject: WebShellIdentity | any = {};
  let userManager: UniteUserManager | UserManagerType | any;
  let internalIsUnite = false;

  const _init = async (): Promise<WebShellIdentity> => {
    const webShellClientVersion = process.env.WEB_SHELL_CLIENT_VERSION ?? ``;

    const isClient = typeof window !== `undefined`;

    try {
      if (isClient) {
        const { isUnite: isIdentityProviderUnite, reason } = getIsIdentityProviderUnite(locale);
        internalIsUnite = isIdentityProviderUnite;

        window.newrelic?.addPageAction(`WEB_SHELL_CLIENT_IDENTITY_INITIALIZED`, {
          isUnite: internalIsUnite.toString(),
          reason,
          webShellClientVersion
        });

        if (internalIsUnite) {
          const { UniteManager } = await import(`./UniteUserManager`);
          userManager = new UniteManager();
        } else {
          // dynamically import oidc client
          const { UserManager, WebStorageStateStore } = await import(`oidc-client`);
          const userStore = new WebStorageStateStore({ store: window.localStorage });
          const OIDCSettings = createOIDCSettings(userStore);
          userManager = new UserManager(OIDCSettings);
          // removed accounts visitData implementation (PR: https://github.com/nike-internal/dotcom.web-shell/pull/576)
          // use silent-renew feature if appropriate
          const user = await userManager.getUser();
          if (user?.expired && isNotACallbackRoute()) {
            await userManager.signinSilent();
          }
        }
      }

      webShellClientInfoDispatch.initializeIdentity(internalIsUnite ? `unite` : `accounts`);

      return identityObject;
    } catch (e: unknown) {
      window.newrelic?.addPageAction(`WEB_SHELL_CLIENT_IDENTITY_INITIALIZE_ERROR`, {
        isUnite: internalIsUnite.toString(),
        webShellClientVersion
      });
      throw e;
    }
  };

  const identityPromise = _init();

  const initialize = async (): Promise<WebShellIdentity> => identityPromise;

  const processSignIn = async (): Promise<User | UniteUser | undefined> => {
    try {
      const user = await userManager.signinCallback();
      return user;
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_PROCESS_SIGN_IN_ERROR`, {
        isUnite: internalIsUnite.toString()
      });
      throw e;
    }
  };

  const processSignOut = async (): Promise<SignoutResponse> => {
    try {
      const response = await userManager.signoutCallback();
      removeVisitData();
      return response;
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_PROCESS_SIGN_OUT_ERROR`, {
        isUnite: internalIsUnite.toString()
      });
      throw e;
    }
  };

  const getUser = async (): Promise<User | UniteUser | undefined> => {
    const user = await userManager.getUser();
    return user;
  };

  const getIsMobileVerified = async (): Promise<boolean> => {
    try {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_IS_MOBILE_VERIFIED`, {
        isUnite: internalIsUnite.toString()
      });
      if (internalIsUnite) {
        const isMobileVerified = await userManager.getUserState(`isMobileVerified`);
        return isMobileVerified === true;
      }

      const user = await identityObject.getUser();
      return user?.profile?.phone_number_verified === true;
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_IS_MOBILE_VERIFIED_ERROR`, {
        isUnite: internalIsUnite.toString()
      });
      throw e;
    }
  };
  const getIsSwooshUser = async (): Promise<boolean> => {
    try {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_IS_SWOOSH_USER`, {
        isUnite: internalIsUnite.toString()
      });
      const user = await identityObject.getUser();
      return isSwooshUser(user?.profile);
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_IS_SWOOSH_USER_ERROR`, {
        isUnite: internalIsUnite.toString()
      });
      throw e;
    }
  };

  const getVisitData = async (): Promise<VisitData> => {
    try {
      if (userManager.getVisitData) {
        // Only the Unite User Manager knows about Visit data
        return userManager.getVisitData();
      }

      // Temporary solution to use Unite's getVisitData solution
      // Previous implementation (PR: https://github.com/nike-internal/dotcom.web-shell/pull/576)
      const visitData = await sharedGetVisitData();
      return visitData;
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_VISIT_DATA_ERROR`, {
        isUnite: internalIsUnite.toString()
      });
      throw e;
    }
  };

  /*eslint-disable-next-line @typescript-eslint/require-await*/
  const signIn = async (args = {}): Promise<void> => {
    try {
      const currentUrl = window.location.href;
      const state = {
        ...args,
        redirectUrl: currentUrl
      };
      return userManager.signinRedirect({ ...args, state });
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_SIGN_IN_ERROR`, {
        isUnite: internalIsUnite.toString()
      });
      throw e;
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const signOut = async (args = {}): Promise<void> => {
    try {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_SIGN_OUT`, {
        isUnite: internalIsUnite.toString()
      });
      const state = {
        ...args,
        redirectUrl: window.location.href
      };

      // Manually log out of Unite for Accounts to clear visitData
      if (!internalIsUnite && window.nike?.unite?.session) {
        window.nike.unite.session.logout(() => {});
      }

      return userManager.signoutRedirect({ ...args, state });
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_SIGN_OUT_ERROR`, {
        isUnite: internalIsUnite.toString()
      });
      throw e;
    }
  };

  const getIsLoggedIn = async (): Promise<boolean> => {
    const profile = await identityObject.getUserProfile();

    if (profile) {
      return profile.userType !== `GUEST`;
    }

    return !!profile;
  };

  /**
   * interface function should allow returning access token to mirror unite's existing functionality today
   * @returns {Promise<string|undefined>} access token if it exists
   */
  const getAccessToken = async (): Promise<string | undefined> => {
    try {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_ACCESS_TOKEN`, {
        isUnite: internalIsUnite.toString()
      });

      // Must ensure that silent-renew is finished before getting token.
      const user = await (await identityPromise).getUser();

      if (user) {
        return user.access_token;
      }
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_ACCESS_TOKEN_ERROR`, {
        isUnite: internalIsUnite.toString()
      });
      throw e;
    }
  };

  const getInitialized = async (): Promise<boolean> => {
    try {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_INITIALIZED`, {
        isUnite: internalIsUnite.toString()
      });
      if (internalIsUnite) {
        // unite has an isInitialized property
        const unite = await userManager?.getUnite();
        return !!unite?.isInitialized;
      }
      return !!userManager;
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_INITIALIZED_ERROR`, {
        isUnite: internalIsUnite.toString()
      });
      throw e;
    }
  };

  const getUserProfile = async (): Promise<UserProfile | undefined> => {
    try {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_USER_PROFILE`, {
        isUnite: internalIsUnite.toString()
      });
      const user = await identityObject.getUser();
      if (user) {
        return shapeUserProfile(user.profile);
      }
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_USER_PROFILE_ERROR`, {
        isUnite: internalIsUnite.toString()
      });
      throw e;
    }
  };

  /**
   * This function is used ONLY internally by the OIDC callback route
   * (i.e. www.nike.com/auth/silent-renew) and should NOT be used
   * directly
   * @returns
   */
  const processReauth = async (): Promise<User | undefined> => {
    try {
      if (!userManager.signinSilentCallback) {
        return;
      }

      return await (userManager as UserManagerType).signinSilentCallback();
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_PROCESS_REAUTH_ERROR`, {
        isUnite: internalIsUnite.toString()
      });
      throw e;
    }
  };

  identityObject = {
    initialize,
    getAccessToken,
    getInitialized,
    getIsLoggedIn,
    getIsMobileVerified,
    getIsSwooshUser,
    getUser,
    getUserProfile,
    getVisitData,
    processReauth,
    processSignIn,
    processSignOut,
    signIn,
    signOut
  };
  return identityObject;
}
