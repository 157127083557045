import type {
  BodaciousTranslations,
  TranslationsCollection,
  WebShellLocale,
  WebShellTranslations
} from '@nike/web-shell-types';

export class DotcomWebShellTranslations implements WebShellTranslations {
  private locale: WebShellLocale;

  private hasTrackedInit = false;

  constructor(locale: WebShellLocale) {
    this.locale = locale;
    this.formatMessage.bind(this);
  }

  /**
   * Downloads translations from corresponding location in S3 for bodacious.
   * Do not re-download if the translations for the given namespace have already been fetched
   */
  fetch: WebShellTranslations['fetch'] = async (
    namespaces,
    options = {}
  ): Promise<TranslationsCollection> => {
    if (!this.hasTrackedInit && typeof window !== `undefined`) {
      window.newrelic?.addPageAction(`WEB_SHELL_CLIENT_TRANSLATIONS_INITIALIZED`, {
        webShellClientVersion: process.env.WEB_SHELL_CLIENT_VERSION ?? ``
      });

      this.hasTrackedInit = true;
    }

    const getURL = (namespace: string): string =>
      `${String(
        options.urlBase ?? `https://${process.env.NEXT_PUBLIC_HOST_NAME}/assets/i18n`
      )}/${namespace}/${String(this.locale.get().hreflang)}.json`;

    try {
      // Fetch all of the translations in parallel
      const results = await Promise.all(
        namespaces.map(async (namespace) => fetch(getURL(namespace)))
      );
      // Then extract the json data from each fetch response
      const jsonArr = await Promise.all<BodaciousTranslations>(
        results.map(async (result) => {
          const json = await result.json();
          return json;
        })
      );

      // Finally combine each namespace into a single object
      const translationsByNamespace = jsonArr.reduce<TranslationsCollection>(
        (allTranslations: TranslationsCollection, translation, index) =>
          Object.assign(allTranslations, { [namespaces[index]]: translation }),
        {}
      );

      return translationsByNamespace;
    } catch (err: unknown) {
      // eslint-disable-next-line no-console
      console.error(`Failed to fetch Translations`, err);
      // If we fail to fetch the translations or we receive malformed data,
      // return an empty object so that we default back to English
      return {};
    }
  };

  // NOTE: In order for formatMessage to work properly in this context, we have to use
  // a method declaration in this manner for it to receive the correct arguments
  // otherwise the result becomes undefined
  //
  // Perhaps this can be re-written in such a way that we're not repeating type information,
  // but we would have to solve for the `this` context not being bound correctly
  //
  // Check out translations.test.tsx in web-shell-client-react if you attempt to change this
  // implementation, as those tests are the most likely thing to break as a result
  formatMessage(messageValue: string, params: Record<string, string> = {}): string {
    // We do not want to use named capture groups in client-side code while Microsoft Edge is still supported
    // eslint-disable-next-line prefer-named-capture-group
    return messageValue.replace(/\{(.*?)\}/gu, (_, token) => params[token]);
  }
}
