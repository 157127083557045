import type { VisitData } from "@nike/web-shell-types";

const onUniteInit = (fn): void => {
  if (typeof window === `undefined`) return;
  window.addEventListener(`nikeUniteInit`, fn, false);
};

export const getUnite = async (): Promise<any> =>
  new Promise((resolve) => {
    if (window.nike?.unite && window.nike.unite.isInitialized) {
      resolve(window.nike.unite);
      return;
    }
    onUniteInit(() => resolve(window.nike?.unite));
  });

export const getVisitData = async (): Promise<VisitData> => {
  const unite = await getUnite();
  return new Promise((resolve, reject) => {
    unite?.session?.getVisitData(resolve, reject);
  });
};
