/* eslint-disable no-console */
import type {
  WebShellWindow,
  ReactInstanceFactory as ReactInstanceFactoryInterface,
  REMOTE_COMPONENTS_NAME
} from "@nike/web-shell-types";
import {
  VENDOR_SCRIPT_REACT_URL,
  VENDOR_SCRIPT_REACT_DOM_URL,
  PRE_FETCH_DEPENDENCIES,
  REMOTE_COMPONENTS_URL
} from "@nike/web-shell-types";

const { REACT, REACT_DOM, REMOTE } = PRE_FETCH_DEPENDENCIES;

type LoadReactOrReactDOM = PRE_FETCH_DEPENDENCIES.REACT | PRE_FETCH_DEPENDENCIES.REACT_DOM;

export type ReactInstanceConfigsType = {
  [key in PRE_FETCH_DEPENDENCIES]: {
    isDefined: boolean;
    fetchPromise: null | Promise<void>;
  };
};

export class ReactInstanceFactory implements ReactInstanceFactoryInterface {
  static _instance: ReactInstanceFactory | undefined;

  private readonly _window: WebShellWindow;

  private configs: ReactInstanceConfigsType = {
    [REACT]: {
      fetchPromise: null,
      isDefined: false
    },
    [REACT_DOM]: {
      fetchPromise: null,
      isDefined: false
    },
    [REMOTE]: {
      fetchPromise: null,
      isDefined: false
    }
  };

  constructor(globalWindow: WebShellWindow) {
    if (ReactInstanceFactory._instance) {
      // eslint-disable-next-line no-constructor-return
      return ReactInstanceFactory._instance;
    }

    this._window = globalWindow;
    this._window._remoteComponents = {};
    this.configs[REACT].isDefined = this.isExistOnWindow(REACT);
    this.configs[REACT_DOM].isDefined = this.isExistOnWindow(REACT_DOM);
    ReactInstanceFactory._instance = this;
  }

  private isExistOnWindow(dependency: string): boolean {
    return dependency in this._window;
  }

  private loadReactOrReactDOMHandleSuccess(key: LoadReactOrReactDOM): void {
    this.configs[key]!.isDefined = true;
    this.configs[key]!.fetchPromise = null;
  }

  private loadReactOrReactDOMHandleFailure(key: LoadReactOrReactDOM, error: Error): void {
    this.configs[key]!.isDefined = false;
    this.configs[key]!.fetchPromise = null;
    console.error(error);
  }

  private async loadReactOrReactDOM(key: LoadReactOrReactDOM): Promise<void> {
    // If React || ReactDOM is loading right now we will return that promise and won't create a new one
    if (this.configs[key]!.fetchPromise) {
      return this.configs[key]!.fetchPromise!;
    }

    const promise = new Promise((resolve, reject) => {
      const vendorScriptURL = key === REACT ? VENDOR_SCRIPT_REACT_URL : VENDOR_SCRIPT_REACT_DOM_URL;
      const script = document.createElement(`script`);
      script.src = vendorScriptURL;
      script.id = key;
      document.body.appendChild(script);
      script.onload = resolve;
      script.onerror = reject;
    })
      // eslint-disable-next-line promise/prefer-await-to-then
      .then(() => this.loadReactOrReactDOMHandleSuccess(key))
      // eslint-disable-next-line promise/prefer-await-to-callbacks
      .catch((error) => this.loadReactOrReactDOMHandleFailure(key, error));

    this.configs[key]!.fetchPromise = promise;
    return promise;
  }

  private async loadRemote(): Promise<void> {
    // If Remote is loading right now we will return that promise and won't create a new one
    if (this.configs[REMOTE].fetchPromise) {
      return this.configs[REMOTE].fetchPromise!;
    }

    const promise = import(`@nike/remote-component`)
      // eslint-disable-next-line promise/prefer-await-to-then,promise/always-return
      .then((response) => {
        this._window._remoteModule = response.RemoteComponent;
        this.configs[REMOTE].isDefined = true;
        this.configs[REMOTE].fetchPromise = null;
      })
      // eslint-disable-next-line promise/prefer-await-to-callbacks
      .catch((error) => {
        this.configs[REMOTE].isDefined = false;
        this.configs[REMOTE].fetchPromise = null;
        console.error(error);
      });

    this.configs[REMOTE].fetchPromise = promise;
    return promise;
  }

  private renderComponentInDom(component: any, renderIntoId: string): void {
    const appendToComponent = document.getElementById(renderIntoId);

    if (!appendToComponent) {
      throw new Error(`Can not find target element`);
    }

    const customWrapperId = `_${renderIntoId}-wrapper`;
    const customWrapperOnDOM = document.getElementById(customWrapperId);

    if (!customWrapperOnDOM) {
      const customWrapper = document.createElement(`div`);
      customWrapper.id = customWrapperId;
      appendToComponent.appendChild(customWrapper);
    }

    this._window[REACT_DOM]!.render(component, document.getElementById(customWrapperId));
  }

  private getNotExistDependencies(dependencies: string[]): string[] {
    return dependencies.reduce((acc, key) => {
      const dependencyConfigs = this.configs[key];

      if (dependencyConfigs.isDefined) {
        return acc;
      }

      return [...acc, key];
    }, []);
  }

  private async fetchDependencies(dependencies: PRE_FETCH_DEPENDENCIES[]): Promise<void> {
    // for React and ReactDOM it does not matter in which order we will fetch those libs
    // But Remote component should be fetched only after React & ReactDOM, because that component requires theme
    let remotePromise: undefined | Function;

    const promises: Promise<void>[] = dependencies.reduce((acc, key) => {
      if (key === REMOTE) {
        remotePromise = this.loadRemote.bind(this);
        return acc;
      }

      return [...acc, this.loadReactOrReactDOM(key)];
    }, []);
    await Promise.allSettled(promises);

    if (remotePromise) {
      await remotePromise.apply(this);
    }
  }

  private async configureDependenciesBeforeRender(dependencies: string[]): Promise<void> {
    const notExistDependencies = this.getNotExistDependencies(dependencies);

    if (!notExistDependencies.length) return;

    await this.fetchDependencies(notExistDependencies as PRE_FETCH_DEPENDENCIES[]);
  }

  private validateDependencies(dependencies: string[]): void {
    const oneOfDependenciesNotExist = dependencies.find(
      (dependency) => !this.configs[dependency].isDefined
    );

    if (oneOfDependenciesNotExist) {
      throw new Error(`"${oneOfDependenciesNotExist}" is not initialized`);
    }
  }

  private checkIsValidReactElement<C extends {} | null | undefined>(component: C): void {
    const isValid = this._window[REACT]?.isValidElement(component);

    if (!isValid) {
      throw new Error(`Is not a React component`);
    }
  }

  private getDependenciesForPrefetch(dependencies: string[]): string[] {
    const dependenciesWithoutNotAllowed = dependencies.reduce((acc, dependency) => {
      const dependencyConfigs = this.configs[dependency];

      if (!dependencyConfigs) {
        console.error(`"${dependency}" does not exist in possible dependencies`);
        return acc;
      }

      return [...acc, dependency];
    }, []);
    const dependenciesExtended = [...dependenciesWithoutNotAllowed];
    const isContainRemoteDependency = dependenciesExtended.includes(REMOTE);

    // We need to have this conditions, because Remote component require React & ReactDOM during fetching it
    if (isContainRemoteDependency && !dependencies.includes(REACT)) {
      dependenciesExtended.push(REACT);
    }
    if (isContainRemoteDependency && !dependencies.includes(REACT_DOM)) {
      dependenciesExtended.push(REACT_DOM);
    }

    return dependenciesExtended;
  }

  private getRemoteComponentByName(remoteComponentName: REMOTE_COMPONENTS_NAME): any {
    if (remoteComponentName in this._window._remoteComponents!) {
      return this._window._remoteComponents![remoteComponentName];
    }

    const ComponentCustom = this._window[REACT]?.createElement(this._window._remoteModule, {
      endpoint: REMOTE_COMPONENTS_URL[remoteComponentName],
      prefetch: false
    });
    this._window._remoteComponents![remoteComponentName] = ComponentCustom;
    return ComponentCustom;
  }

  prefetchDependencies: ReactInstanceFactoryInterface["prefetchDependencies"] = async (
    dependencies
  ) => {
    const dependenciesExtended = this.getDependenciesForPrefetch(dependencies);
    const notExistDependencies = this.getNotExistDependencies(dependenciesExtended);

    if (!notExistDependencies.length) return;

    await this.fetchDependencies(notExistDependencies as PRE_FETCH_DEPENDENCIES[]);
  };

  renderRemoteComponent: ReactInstanceFactoryInterface["renderRemoteComponent"] = async (
    remoteComponentName,
    renderIntoId
  ) => {
    const dependencies = [REACT, REACT_DOM, REMOTE];
    await this.configureDependenciesBeforeRender(dependencies);

    this.validateDependencies(dependencies);
    const component = this.getRemoteComponentByName(remoteComponentName);
    this.checkIsValidReactElement(component);

    this.renderComponentInDom(component, renderIntoId);
  };

  render: ReactInstanceFactoryInterface["render"] = async (component, renderIntoId) => {
    const dependencies = [REACT, REACT_DOM];
    await this.configureDependenciesBeforeRender(dependencies);

    this.validateDependencies(dependencies);
    this.checkIsValidReactElement(component);

    this.renderComponentInDom(component, renderIntoId);
  };
}
